module.exports = [{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-27136969-16","anonymize":true,"respectDNT":true},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":656,"showCaptions":true,"tracedSVG":true},
    },{
      plugin: require('/opt/render/project/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
